import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { stepFive } from "../../../services/steps.services";
import {
	findSpecificError,
	isSucursalWeb,
	// showEmotions,
	setErrorTitleAndBody,
} from "../../../utils";
import SendIcon from "@mui/icons-material/Send";
import Confeti from "../../Reactions/Confeti";
import useIsSolicitudAmpliacion from "../../../hooks/useIsSolicitudAmpliacion";
import useIsSoliciudRefinanciacion from "../../../hooks/useIsSolicitudRefinanciacion";

const StepFour = () => {
	// eslint-disable-next-line no-unused-vars
	const isRefinanciacion = useIsSoliciudRefinanciacion();
	const isAmpliacion = useIsSolicitudAmpliacion();
	const dispatch = useDispatch();
	const dataset = useSelector((state) => state.config.dataset);
	const stepConf = useSelector((state) => state.config.step);
	const [loading, setLoading] = React.useState(true);
	const getUrlToLogin = () => {
		const documento = "d=" + dataset.cedula;
		const tipoDocumento = "td=" + 3;
		const tokenToNewPassword = "ttnp=" + dataset.createPassword;
		return `${dataset.myAccount}/#/new-password?${documento}&${tipoDocumento}&${tokenToNewPassword}`;
	};
	React.useEffect(() => {
		dispatch({ type: "show/veryHappyFace" });
		dispatch({ type: "show/spinner" });

		stepFive(
			dataset.id,
			dataset.cedula,
			dataset.telefono,
			dataset.canal,
			dataset.sucursal,
			dataset.token
		)
			.then((response) => {
				dispatch({
					type: "step/four",
					payload: {
						tyc: dataset.operador
							? response.data.terminosYCondicionesRemotoBlancoPDF
							: response.data.terminosYCondicionesRemotoPDF,
						tycc: dataset.operador
							? response.data.terminosYCondicionesBlancoClientePDF
							: response.data.terminosYCondicionesBlancoClientePDF,
						idExterno: response.data.valeExterno,
						instructivo: response.data.instructivoPagoPDF
							? response.data.instructivoPagoPDF
							: null,
						createPassword: response.data.tokenToNewPassword
							? response.data.tokenToNewPassword
							: null,
						montoDesembolsar: response.data.vale.montoDesembolsar,
						phoneContactForHelp: response.data.phoneContactForHelp,
					},
				});
			})
			.then(() => {
				setLoading(false);
				dispatch({ type: "hide/emotions" });
				dispatch({ type: "hide/spinner" });
			})
			.catch((error) => {
				dispatch({ type: "hide/emotions" });
				dispatch({ type: "hide/spinner" });
				if (findSpecificError("voucher_generation_error", error)) {
					dispatch({
						type: "show/error",
						payload: {
							errorTitle: "Su vale se está procesando",
							errorBody: "Contactaremos con usted cuando esté listo",
						},
					});
				} else if (findSpecificError("cannot_continue_without_asesor", error)) {
					dispatch({
						type: "show/error",
						payload: {
							errorTitle: "Su vale esta esperando aprobación",
							errorBody: "Uno de nuestros agentes se contactara con usted",
						},
					});
				} else if (
					findSpecificError("voucher_inherited_not_valid", error) ||
					findSpecificError("expired", error)
				) {
					dispatch({
						type: "show/error",
						payload: {
							errorTitle: "Esta solicitud no puede ser continuada.",
							errorBody: "Debe iniciar una nueva",
						},
					});
				} else {
					dispatch({
						type: "show/error",
						payload: setErrorTitleAndBody(error),
					});
				}
			});
		// eslint-disable-next-line
	}, []);
	React.useEffect(() => {
		window.scrollTo(0, 0);
		dispatch({ type: "hide/display-step-progress" });
		dispatch({ type: "hide/main" });
	}, []);

	if (loading) return <Box />;
	return (
		<Confeti>
			<Box display="flex" flexDirection="column" gap={4}>
				<Box
					display="flex"
					flexDirection="column"
					gap={2}
					justifyItems="center"
					alignItems="center"
					sx={{
						backgroundColor: "background.default",
						borderRadius: "1rem",
						padding: { xs: "1rem", sm: "2rem", md: "2rem", lg: "3rem" },
					}}
				>
					<Typography
						variant="h4"
						gutterBottom
						fontWeight="bolder"
						sx={{ textAlign: "center" }}
					>
						&iexcl;
						{dataset.nombre},
						{isRefinanciacion
							? " se ha procesado correctamente la refinanciación de tu préstamo!"
							: " tú solicitud fué Pre-Aprobada correctamente!"}
						!
					</Typography>
					{dataset.idExterno !== "" && (
						<Typography>Vale: {dataset.idExterno}</Typography>
					)}

					{(dataset.canal === "PASARELA" ||
						dataset.canal === "SOLICITUD_WEB" ||
						isSucursalWeb(dataset.sucursal)) && (
						<>
							<Typography variant="h5" sx={{ textAlign: "center" }}>
								{/* {dataset.montoDesembolsar ? (
									<>
										Podrás disponer del dinero en cualquier{" "}
										<b>{dataset.opcion}</b>
									</>
								) : (
									<>
										Deberas pasar por cualquier <b>{dataset.opcion}</b> para
										firmar la documentación
									</>
								)}{" "}
								presentando tu{" "}
								<b>cédula de identidad y una fotocopia de la misma</b>. */}
								{isRefinanciacion ? (
									<>
										Deberas pasar por cualquier <b>{dataset.opcion}</b> para
										firmar la documentación presentando tu{" "}
										<b>cédula de identidad y una fotocopia de la misma</b>.
									</>
								) : (
									<>
										Deberas pasar por cualquier <b>{dataset.opcion}</b> para{" "}
										<b>validar tu identidad mediante tu huella dactilar</b> y
										firmar la documentación presentando tu{" "}
										<b>
											cédula de identidad vigente y una fotocopia de la misma
										</b>
										.
									</>
								)}
							</Typography>

							<Typography variant="body1" sx={{ textAlign: "center" }}>
								Ante cualquier inconveniente puedes contactarte al{" "}
								{dataset.phoneContactForHelp} en el horario de 10:00 a 16:00 y
								uno de nuestros agentes podrá ayudarte
							</Typography>
						</>
					)}

					<Box
						sx={{
							textAlign: "center",
						}}
					>
						{dataset.operador &&
						stepConf.showViaFinanciera &&
						dataset.canal !== "PASARELA" &&
						dataset.canal !== "SOLICITUD_WEB" &&
						!isSucursalWeb() ? (
							<Box>
								<Box>
									<a
										style={{ color: "#000000" }}
										href={dataset.urlTerminosFinal}
										target="_blank"
										rel="noreferrer"
										download
									>
										Terminos y condiciones
									</a>
								</Box>
								<Box>
									<a
										style={{ color: "#000000" }}
										href={dataset.urlTerminosFinalCliente}
										target="_blank"
										rel="noreferrer"
										download
									>
										Terminos y condiciones (Cliente)
									</a>
								</Box>
								{dataset.urlInstructivo && (
									<Box style={{ marginTop: "2rem" }}>
										<a
											style={{ color: "#000000" }}
											href={dataset.urlInstructivo}
											target="_blank"
											rel="noreferrer"
											download
										>
											Instructivo de pago
										</a>
									</Box>
								)}
							</Box>
						) : (
							<Box>
								<Box>
									<a
										style={{ color: "#000000" }}
										href={dataset.urlTerminosFinalCliente}
										target="_blank"
										rel="noreferrer"
										download
									>
										Leer términos y condiciones
									</a>
								</Box>
								{dataset.urlInstructivo && (
									<Box style={{ marginTop: "2rem" }}>
										<a
											style={{ color: "#000000" }}
											href={dataset.urlInstructivo}
											target="_blank"
											rel="noreferrer"
											download
										>
											Instructivo de pago
										</a>
									</Box>
								)}
							</Box>
						)}
					</Box>
				</Box>
				{((isSucursalWeb() && dataset.canal === "SOLICITUD_WEB") ||
					dataset.canal === "PASARELA") &&
					dataset.createPassword && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
							gap={4}
						>
							<Button
								variant="contained"
								color="button"
								sx={{ alignSelf: "center" }}
								onClick={() => window.open(getUrlToLogin(), "_self")}
								endIcon={<SendIcon />}
							>
								Ir a mi cuenta
							</Button>
						</Box>
					)}
			</Box>
		</Confeti>
	);
};

export default StepFour;
